import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";

export const UserView = props => {
    const [user, setUser] = useState(props.user);

    useEffect(() => {
    });

    return (
        <Fragment>
            {props.showHeader ?
                <Fragment>
                    {user.ID ?
                        <h5>Пользователь с ID {user.ID}</h5>
                        :
                        <h5>Новый пользователь</h5>
                    }
                </Fragment>
                :
                ""
            }
            {user?.ID ?
                <div className={"row"}>
                    <div className={"col mb-3"}>
                        <div className={"card h-100"}>
                            <div className={"card-body"}>
                                <h5>Логин</h5>
                                {user.login}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                        <div className={"card h-100"}>
                            <div className={"card-body"}>
                                <h5>Авторизация</h5>
                                {user.lastActivity ? user.lastActivity : "не авторизовался ранее"}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                        <div className={"card h-100"}>
                            <div className={"card-body"}>
                                <h5>Email</h5>
                                {user?.email ?
                                    user.email
                                    : <span className={"text-danger"}>не установлен email для пользователя!</span>}
                            </div>
                        </div>
                    </div>
                    <div className={"col mb-3"}>
                        <div className={"card h-100"}>
                            <div className={"card-body"}>
                                <h5>Роль</h5>
                                {user?.role ? user.role.name : "не установлена роль"}
                            </div>
                        </div>
                    </div>
                </div>
                :
                ""
            }

        </Fragment>
    );
};

UserView.propTypes = {
    user: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]),
    person: PropTypes.object,
    context: PropTypes.number,
    showHeader: PropTypes.bool,
};
