import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorContainer } from "../dom/ErrorContainer";
import { ButtonPrimary } from "../dom/ButtonPrimary";
import { UserView } from "./UserView";
import { UserForm } from "./UserForm";
import { Utils } from "../Utils";
import { ElementsContext } from "../enums/ElementsContext";
import { PersonSection } from "./PersonSection";
import { Checkbox } from "../dom/Checkbox";
import { RolesCodes } from "../enums/RolesCodes";

const axios = require("axios").default;

export const UserSection = props => {

    let newUserTemplate = {
        "ID": "",
        "role_id": "",
        "role": null,
        "person_id": props.person?.ID,
        "person": props.person || {},
        "password": "",
        "login": Utils.setLoginFromFio(props.person?.f, props.person?.i, props.person?.o),
        "email": props.person?.email,
    };

    const [newUser, setNewUser] = useState(newUserTemplate);
    const [roles, setRoles] = useState([]);
    const [person, setPerson] = useState(props.person);
    const [user, setUser] = useState(props.user);
    const [editMode, setEditMode] = useState(props.edit);
    const [useUser, setUseUser] = useState(!!props.user);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
    });

    useEffect(() => {
        let cleanupFunction = false;

        function rolesCallback(roles) {
            if (!cleanupFunction && roles) {
                setRoles(roles);
                if (!user) {
                    if (roles.length > 0) {
                        newUserTemplate.role = getContextRole(roles);
                    }
                    setNewUser(newUserTemplate);
                }

                if (!user?.role?.length && user?.role_id && roles.length > 0) {
                    let user_role = roles.filter((role, index) => {
                        return +role.ID === +user.role_id;
                    }).shift();
                    setUser({ ...user, ...{ role: user_role } });
                }
            }
        }

        getRoles(rolesCallback);
        return () => {
            cleanupFunction = true;
        };
    }, []);

    const onUserChange = (user_id = null) => {
        if (user_id) {
            getUser(user_id);
        } else {
            setUser({});
            props.onUserChange?.({});
            setEditMode(false);
        }
    };

    const onPersonSet = (person = {}) => {
        let data = {
            person_id: person.ID,
            person: person,
        };
        setUser({ ...user, ...data });
        setPerson(person);
    };

    const handleUseUserCheckbox = async (event) => {
        event.persist();
        let isChecked = event.target.checked;
        if (!isChecked && user) {
            const confirmRes = await app.getConfirm("Данные пользователя будут удалены?");
            if (!confirmRes) {
                return false;
            }
            setUser(newUser);
        }
        setUseUser(isChecked);
    };

    const getRoles = (callback) => {
        axios.get('/api/roles/')
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                setErrors(errors => [...errors, error.response.data]);
                callback(false);
            });
    };

    const getUser = (user_id) => {
        axios.get(`/api/users/${user_id}/`)
            .then((response) => {
                setUser(response.data);
                setEditMode(false);
                if (typeof props.onUserChange === "function") {
                    props.onUserChange(response.data);
                }
            })
            .catch((error) => {
                setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                if (app.debug) {
                    throw new Error(error);
                }
            });
    };

    const getContextRole = (roles) => {
        let contextRoleCode, contextRole = {};
        switch (props.context) {
            case ElementsContext.STUDENT:
                contextRoleCode = RolesCodes.STUDENT;
                break;
            case ElementsContext.TEACHER:
                contextRoleCode = RolesCodes.TEACHER;
                break;
        }
        if (contextRoleCode) {
            contextRole = roles.filter((role, index) => {
                return role.code === contextRoleCode;
            }).shift();
        }
        return contextRole;
    };

    const RenderButtons = props => {
        return (
            <Fragment>
                {!editMode ?
                    <Fragment>
                        {user?.ID ?
                            <ButtonPrimary
                                text={"Редактировать"}
                                onClick={() => {
                                    setEditMode(true);
                                }}
                            />
                            :
                            <ButtonPrimary
                                text={"Создать пользователя"}
                                onClick={() => {
                                    setEditMode(true);
                                }}
                            />
                        }
                    </Fragment>
                    :
                    ""
                }
            </Fragment>
        );
    };

    const RenderHeader = localProps => {
        return (
            <Fragment>
                {props.showHeader ?
                    <Fragment>
                        {user?.ID ?
                            <h5>Пользователь # {user.ID}</h5>
                            :
                            <h5>Новый пользователь</h5>
                        }
                    </Fragment>
                    :
                    ""
                }
            </Fragment>
        );
    };

    return (
        <Fragment>
            {errors.length > 0 ?
                <ErrorContainer arErrors={errors} />
                :
                <Fragment>
                    {props.showPersonSection ?
                        <div className="row">
                            <div className="col-12 mb-3">
                                <PersonSection
                                    context={ElementsContext.USER}
                                    onPersonSet={onPersonSet}
                                    editable={true}
                                    edit={props.edit || false}
                                    personId={user?.person_id || ""}
                                    person={user?.person || {}} />
                            </div>
                        </div>
                        :
                        ""}
                    <div className="row align-items-center mb-3">
                        <div className="col">
                            <RenderHeader />
                        </div>
                        <div className="col-2">
                            {user?.deleted ?
                                <span className={"text-danger"}>пользователь удалён!</span>
                                :
                                ""
                            }
                        </div>
                        <div className="col-2">
                            <RenderButtons />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {!editMode ?
                                <UserView
                                    context={props.context}
                                    showHeader={false}
                                    user={user}
                                    person={person || {}} />
                                :
                                <Fragment>
                                    {props.context ?
                                        <div className={"mb-2"}>
                                            <Checkbox
                                                label={"Дать доступ к Дневнику (установить пользователя)"}
                                                name={"useUser"}
                                                selected={useUser || editMode}
                                                onChange={handleUseUserCheckbox} />
                                        </div>
                                        :
                                        ""
                                    }
                                    {useUser || editMode ?
                                        <UserForm
                                            context={ElementsContext.USER}
                                            user={user || newUserTemplate}
                                            person={person || person?.user || null}
                                            roles={roles || []}
                                            showHeader={false}
                                            onCancelButtonClick={() => setEditMode(false)}
                                            onUserChange={onUserChange} />
                                        :
                                        ""
                                    }
                                </Fragment>
                            }
                        </div>
                    </div>

                </Fragment>
            }
        </Fragment>
    );

};

UserSection.propTypes = {
    user: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]),
    person: PropTypes.object,
    edit: PropTypes.bool,
    showPersonSection: PropTypes.bool,
    context: PropTypes.number,
    showHeader: PropTypes.bool,
    onUserChange: PropTypes.func,
};


